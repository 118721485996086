import React from 'react'
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleTranslate from './Translate';


function Header2() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [userType, setUserType] = useState("")
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState("")
    const [user, setUser] = useState({})
    const [wishlist, setWishlist] = useState([])
    const [compare, setCompare] = useState([])
    const [search, setSearch] = useState('')
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const [otp, setOtp] = useState("")
    const [generatedOtp, setGeneratedOtp] = useState("")
    const [searchInput, setSearchInput] = useState('');
    const [filteredCities, setFilteredCities] = useState([]);
    const [suggestion, setSuggestion] = useState([])
    const [changeLanguage, setChangeLanguage] = useState('es');
    const [langModal, setLangModal] = useState(false)
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const initialFormData = {
        purpose: "",
        search: "",
        category: "",
        price: "",
    };
    const [formData, setFormData] = useState(initialFormData);

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");




    useEffect(() => {
        if (searchInput === '') {
            setFilteredCities([]);
        } else {
            const filtered = suggestion.filter(city =>
                city.toLowerCase().startsWith(searchInput.toLowerCase())
            );
            setFilteredCities(filtered);
        }
    }, [searchInput]);


    // A function to handle the change of the input fields
    const handleChange = (e) => {
        let { name, value, type, files } = e.target;
        console.log(name, value, type, files)

        if (value === 'All Categories') {
            value = ''
        }



        if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        if (!email) {
            setError("Please enter registered email.");
            return;
        }

        if (+generatedOtp !== +otp) {
            toast.error("OTP is invalid");
            return
        }

        try {
            const response = await fetch(`${apiUrl}/employee/forgot-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password })
            });

            const responseData = await response.json();


            if (responseData.status === "success") {
                console.log("Password Reset");

                toast.success(responseData.message);

                setTimeout(() => {
                    window.location.href = "/";
                }, 2500)

            } else {
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }

    }

    const handleEmailVerificationForRegister = async (e) => {
        e.preventDefault();


        console.log('email =>', email)


        if (!email) {
            toast.error("Please enter registered email.");
            return;
        }

        toast.success("Sending...");

        try {

            const response = await fetch(`${apiUrl}/employee/email-verify-for-register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email })
            });

            const responseData = await response.json();


            if (responseData.status === "success") {
                console.log("Email verified");

                setIsEmailVerified(true)

                setGeneratedOtp(responseData?.generatedOTP);

            } else {
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }

    }

    const handleEmailVerification = async (e) => {
        e.preventDefault();


        console.log('email =>', email)


        if (!email) {
            toast.error("Please enter registered email.");
            return;
        }

        toast.success("Sending...");

        try {

            const response = await fetch(`${apiUrl}/employee/email-verify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email })
            });

            const responseData = await response.json();


            if (responseData.status === "success") {
                console.log("Email verified");

                setIsEmailVerified(true)

                setGeneratedOtp(responseData?.generatedOTP);

            } else {
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }

    }

    useEffect(() => {

        async function getSuggestion() {

            const resData = await fetch(`${apiUrl}/property/suggestions`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${apiKey}`
                    }
                }
            )
            const response = await resData.json();

            if (response.status !== "error") {
                setSuggestion(response.data)
            }

        }

        getSuggestion()


    }, [apiUrl, apiKey])



    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;

            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
            } else if (!localStorage.getItem("Token")) {
                setUser({})
            }
        }

        if (Token) {
            console.log('Token =>', Token)
            getUser();
        }

    }, [Token]);

    useEffect(() => {
        async function getWishlist() {
            const url = `${apiUrl}/property/getWishlist/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setWishlist(response.data)
            }
        }
        if (user?.id) {
            getWishlist()
        }

    }, [user, apiUrl, Token]);

    useEffect(() => {
        async function getCompare() {
            const url = `${apiUrl}/property/getCompare/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setCompare(response.data)
            }
        }
        if (user?.id) {
            getCompare()
        }

    }, [user, apiUrl, apiKey]);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError("Please enter both email and password.");
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/employee/customerlogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const responseData = await response.json();


            if (responseData.status === "success") {
                console.log("Login successful");

                toast.success("Login successful");
                localStorage.setItem("Token", responseData.data.Token);
                setTimeout(() => {
                    window.location.href = "/";
                }, 2500)
            } else {
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };


    const handleRegister = async (e) => {
        e.preventDefault();

        // if (+generatedOtp !== +otp) {
        //     toast.error("OTP is invalid");
        //     return
        // }

        try {
            const response = await fetch(`${apiUrl}/employee/add-customer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password, name, userType }),
            });

            const responseData = await response.json();


            if (responseData.status === "success") {
                console.log("Registration successful");

                toast.success(responseData.message);
                localStorage.setItem("Token", responseData.data.Token);
                setTimeout(() => {
                    window.location.href = "/";
                }, 2500)
            } else {
                throw new Error(responseData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }


    const handleLogOut = () => {
        localStorage.removeItem('Token')
        window.location.href = '/'
        setUser({})
        toast.success('Logged Out !!')
    }



    // A function to handle the submit of the property form
    const handleWishlist = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // A function to handle the submit of the property form
    const handleCompare = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            {/*=============== basic  ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />
            <div>
                {/* header */}
                <header className="main-header">
                    {/*  logo  */}
                    <div className="logo-holder" role='button'>
                        <img src="images/logo.png"
                            alt="logo"
                            onClick={() => {
                                window.location.href = "/"
                            }}
                        />
                    </div>
                    {/* logo end  */}
                    {/* <div className="cart-btn" style={{ maxHeight: '20px', maxWidth: '50px' }} data-microtip-position="bottom">
                        <GoogleTranslate />
                    </div> */}
                    {/* nav-button-wrap*/}
                    <div className="nav-button-wrap color-bg nvminit">
                        <div className="nav-button">
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    {/* nav-button-wrap end*/}
                    {/* header-search button  */}

                    {/* <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form> */}



                    <div className='header-searchbar-responsive-div' onClick={() => {
                        setIsSearchOpen((prev) => {
                            if (prev === false) {
                                return true
                            } else {
                                return false
                            }
                        })
                    }}>
                        <i className="fa-solid fa-magnifying-glass" style={{ fontSize: '1.1rem' }} />
                    </div>
                    {/* <input className='header-searchbar-responsive-toggle'
                        type='checkbox'
                        id="toggle"
                    /> */}

                    {isSearchOpen &&
                        <div className='header-searchbar-responsive'>
                            <input
                                style={{ width: '100%', height: '100%', position: 'absolute', left: '5px', border: 'transparent' }}
                                type="text"
                                name='search'
                                placeholder="Search..."
                                value={searchInput}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        let query = ""
                                        for (const key in formData) {
                                            if (formData[key] !== null && formData[key] !== "") {
                                                if (query === "") {
                                                    query = query + `${key}=${formData[key]}`
                                                } else {

                                                    query = query + `&${key}=${formData[key]}`
                                                }
                                            }
                                        }
                                        window.location.href = `/listing?${query}`
                                    }
                                }}
                                onChange={(e) => {
                                    console.log(e.target.value)

                                    handleChange(e)
                                    setSearchInput(e.target.value)
                                }}
                            />

                            {filteredCities.length > 0 && (
                                <ul style={{
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0,
                                    marginTop: '10px',
                                    display: 'block',
                                    position: 'absolute',
                                    background: 'white',
                                    width: '100%',
                                    top: '40px',
                                    borderRadius: '5px',
                                    textAlign: 'left',
                                    paddingLeft: '10px',
                                }}>
                                    {filteredCities.map((suggestion, index) => (
                                        <li key={index}
                                            className='searchbar-suggestion'
                                            onClick={(e) => {
                                                e.preventDefault()

                                                window.location.href = `/listing?search=${suggestion}`
                                                setSearchInput(suggestion)
                                            }}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            <div style={{
                                position: 'absolute',
                                right: '25px',
                                top: '10px'
                            }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    let query = ""
                                    for (const key in formData) {
                                        if (formData[key] !== null && formData[key] !== "") {
                                            if (query === "") {
                                                query = query + `${key}=${formData[key]}`
                                            } else {

                                                query = query + `&${key}=${formData[key]}`
                                            }
                                        }
                                    }
                                    window.location.href = `/listing?${query}`
                                }}
                            >
                                <i className="fa-solid fa-search" />

                            </div>

                        </div>
                    }



                    <div
                        className='header-searchbar'
                    >
                        <input
                            className='header-searchbar-input'
                            type="text"
                            name='search'
                            placeholder="Search..."
                            value={searchInput}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    let query = ""
                                    for (const key in formData) {
                                        if (formData[key] !== null && formData[key] !== "") {
                                            if (query === "") {
                                                query = query + `${key}=${formData[key]}`
                                            } else {

                                                query = query + `&${key}=${formData[key]}`
                                            }
                                        }
                                    }
                                    window.location.href = `/listing?${query}`
                                }
                            }}
                            onChange={(e) => {
                                console.log(e.target.value)

                                handleChange(e)
                                setSearchInput(e.target.value)
                            }}

                        // style={{ width: '200px', padding: '10px' }}
                        />
                        {filteredCities.length > 0 && (
                            <ul style={{
                                listStyle: 'none',
                                padding: 0,
                                margin: 0,
                                marginTop: '10px',
                                display: 'block',
                                position: 'absolute',
                                background: 'white',
                                width: '100%',
                                top: '40px',
                                borderRadius: '5px',
                                textAlign: 'left',
                                paddingLeft: '10px',
                            }}>
                                {filteredCities.map((suggestion, index) => (
                                    <li key={index}
                                        className='searchbar-suggestion'
                                        onClick={(e) => {
                                            e.preventDefault()

                                            window.location.href = `/listing?search=${suggestion}`
                                            setSearchInput(suggestion)
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}

                        <div style={{
                            height: '40px',
                            width: '50px'
                        }}
                            onClick={(e) => {
                                e.preventDefault()
                                let query = ""
                                for (const key in formData) {
                                    if (formData[key] !== null && formData[key] !== "") {
                                        if (query === "") {
                                            query = query + `${key}=${formData[key]}`
                                        } else {

                                            query = query + `&${key}=${formData[key]}`
                                        }
                                    }
                                }
                                window.location.href = `/listing?${query}`
                            }}
                        >
                            <i className="fa-solid fa-search" />

                        </div>
                    </div>

                    {/* <div className="header-search-button">
                        <i className="fa-solid fa-search" />

                    </div> */}



                    {/* <form method="post" name="registerform"

                    style={{
                        float: 'left',
                        marginLeft: "60px",
                        width: "450px",
                        height: '40px',
                        borderRadius: "40px",
                        lineHeight: '40px',
                        position: 'relative',
                        top: '15px',
                        background: '#F5F7FB',
                        cursor: 'pointer',
                        color: '#144273',
                        fontWeight: '500',

                    }}
                    >

                        <input
                            style={{
                                float: 'left',
                                marginLeft: "60px",
                                width: "450px",
                                height: '40px',
                                // borderRadius: "40px",
                                lineHeight: '40px',
                                position: 'relative',
                                top: '15px',
                                background: '#F5F7FB',
                                cursor: 'pointer',
                                color: '#144273',
                                fontWeight: '500',
                                // overflow: 'hidden'
                            }}
                            type="text"
                            name='search'
                            placeholder="Search..."
                            onChange={(e) => {
                                handleChange(e)
                            }}

                        />

                        <div onClick={(e) => {
                            e.preventDefault()
                            let query = ""
                            for (const key in formData) {
                                if (formData[key] !== null && formData[key] !== "") {
                                    if (query === "") {
                                        query = query + `${key}=${formData[key]}`
                                    } else {

                                        query = query + `&${key}=${formData[key]}`
                                    }
                                }
                            }
                            window.location.href = `/listing?${query}`
                        }}>
                            <i className="fa-solid fa-search" />
                        </div >


                    </form> */}

                    {/* <div className="header-search-button">
                        <i className="fa-solid fa-search" />
                        <form method="post" name="registerform">
                            <input
                                type="text"
                                name='search'
                                placeholder="Address , Street , State..."
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                            />
                            <div onClick={(e) => {
                                e.preventDefault()
                                let query = ""
                                for (const key in formData) {
                                    if (formData[key] !== null && formData[key] !== "") {
                                        if (query === "") {
                                            query = query + `${key}=${formData[key]}`
                                        } else {

                                            query = query + `&${key}=${formData[key]}`
                                        }
                                    }
                                }
                                window.location.href = `/listing?${query}`
                            }}>
                                <i className="fa-solid fa-search" />
                            </div >

                        </form>
                        <span>Search...</span>

                    </div> */}
                    {/* header-search button end  */}
                    {/*  add new  btn */}
                    <div className="add-list_wrap" >
                        <button
                            className="add-list color-bg" 
                            onClick={() => {
                                if (!(Object.keys(user).length)) {
                                    document.getElementById('open-login').click();
                                } else {
                                    window.location.href = '/add';
                                }
                            }
                            }
                            style={{ border: 'none' }}
                        >
                            <i className="fa-solid fa-plus" /> <span>Add Listing</span>
                        </button>
                    </div>
                    {/*  add new  btn end */}
                    {/*  add new  btn */}
                    {!(Object.keys(user).length) && <div className="add-list_wrap" >
                        <button className="add-list color-bg  modal-open" id='open-login' style={{ border: 'none' }} >
                            <i className="fa-solid fa-plus" /> <span>Professional</span>
                        </button>
                    </div>}
                    {/*  add new  btn end */}
                    {/*  header-opt_btn */}
                    <div className="header-opt_btn " data-microtip-position="bottom"
                        onClick={() => {
                            setLangModal((prev) => {
                                if (prev === true) {
                                    return false
                                } else {
                                    return true
                                }
                            })
                        }}

                    >
                        <span>
                            <i className="fa-solid fa-language" style={{ fontSize: '22px' }} />
                        </span>
                    </div>
                    {/*  header-opt_btn end */}
                    {/*  cart-btn   */}
                    <div
                        className="cart-btn show-header-modal"
                        data-microtip-position="bottom"
                    >
                        <i className="fa-solid fa-bell" />
                        <span className="cart-btn_counter color-bg">{compare.length + wishlist.length}</span>
                    </div>
                    {/*  cart-btn end */}

                    {/*header-opt-modal*/}

                    {langModal &&
                        <div className="header-opt-modal vis-head-mod">
                            <div className="header-opt-modal-container hopmc_init">
                                <div className=" lang-item fl-wrap">
                                    <h4 style={{
                                        display: "block",
                                        cursor: "pointer",
                                        textAlign: "left",
                                        padding: "0 20px 6px",
                                        color: "#144273",
                                        fontWeight: "600",
                                        fontSize: "13px",
                                        position: "relative"
                                    }}>
                                        Language: <span>EN</span>
                                    </h4>
                                    <div style={{
                                        textAlign: "left",
                                        padding: "0 20px",
                                        fontWeight: "500",
                                        marginTop: "4px",
                                        gap: '2px'
                                    }}>
                                        <GoogleTranslate setChangeLanguage={setChangeLanguage} />
                                        <a
                                            onClick={() => {
                                                changeLanguage && changeLanguage('en')
                                                setLangModal(false)
                                            }}
                                            className="current-lan "
                                            data-lantext="EN"
                                            style={{
                                                cursor: "pointer",
                                                color: "#7d93b2",
                                                display: "block",
                                                padding: "4px 6px",
                                                position: "relative",
                                            }}
                                        >
                                            English
                                        </a>
                                        <a
                                            onClick={() => {
                                                changeLanguage && changeLanguage('es')
                                                setLangModal(false)
                                            }}
                                            className="current-lan"
                                            data-lantext="ES"
                                            style={{
                                                cursor: "pointer",
                                                color: "#7d93b2",
                                                display: "block",
                                                padding: "4px 6px",
                                                position: "relative",
                                            }}
                                        >
                                            Spanish
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }



                    {/*header-opt-modal end */}
                    {/*  login btn */}
                    {user?.name &&
                        <div
                            className="cart-btn tolt"
                            data-microtip-position="bottom"
                            data-tooltip={`${user.name}`}
                            onClick={() => {
                                window.location.href = '/dashboard'
                            }}
                        >
                            <div className="dashbard-menu-avatar fl-wrap m-3">
                                <img src={user?.userDetails[0]?.photo ? `${apiUrl}/uploads/${user?.userDetails[0]?.photo}` : "images/avatar/5.jpg"} alt="user" />
                            </div>
                            {/* <h4 className='m-0 d-block'>
                                {user.name}
                            </h4> */}
                        </div>}
                    {!user?.name && <div className="show-reg-form modal-open">
                        <i className="fa-solid fa-user" />
                        <span>Sign In</span>
                    </div>}
                    {/*  login btn  end */}
                    {/*  navigation */}
                    <div className="nav-holder main-menu">
                        <nav>
                            <ul className="no-list-style">
                                <li>
                                    <a href="#">
                                        Pages <i className="fa-solid fa-caret-down" />
                                    </a>
                                    {/*second level */}
                                    <ul>
                                        <li>
                                            <a href="/about">About</a>
                                        </li>
                                        <li>
                                            <a href="/contact">Contacts</a>
                                        </li>
                                        <li>
                                            <a href="/help">Help FAQ</a>
                                        </li>
                                        <li>
                                            <a href="/pricing">Pricing Plan</a>
                                        </li>
                                        <li>
                                            <a href="/compare">Compare Property</a>
                                        </li>
                                        <li>
                                            <a href="/blog">Blog</a>
                                        </li>
                                    </ul>
                                    {/*second level end*/}
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* navigation  end */}
                    {/* header-search-wrapper */}
                    <div className="header-search-wrapper novis_search">
                        <div className="header-serach-menu">
                            <div className="custom-switcher fl-wrap">
                                <div className="fieldset fl-wrap">
                                    <input
                                        type="radio"
                                        name="purpose"
                                        id="buy_sw"
                                        className="tariff-toggle"
                                        defaultChecked={true}
                                        value='wantToSell'
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="buy_sw">Buy</label>
                                    <input
                                        type="radio"
                                        name="purpose"
                                        className="tariff-toggle"
                                        id="rent_sw"
                                        value='wantToRent'
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="rent_sw" className="lss_lb">
                                        Rent
                                    </label>
                                    <span className="switch color-bg" />
                                </div>
                            </div>
                        </div>
                        <div className="custom-form">
                            <form method="post" name="registerform">
                                <label>Keywords </label>
                                <input
                                    type="text"
                                    name='search'
                                    placeholder="Address , Street , State..."
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                />
                                <label>Categories</label>
                                <select
                                    data-placeholder="Categories"
                                    className="selectBox"
                                    name='category'
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                >
                                    <option>All Categories</option>
                                    <option>House</option>
                                    <option>Apartment</option>
                                    <option>Hotel</option>
                                    <option>Villa</option>
                                    <option>Office</option>
                                </select>
                                <label style={{ marginTop: 10 }}>Price Range 0 to {formData.price ? formData.price : 'Max'}</label>
                                <div className="price-rage-item fl-wrap">
                                    <input
                                        type="range"
                                        className="w-100"
                                        min={100}
                                        max={100000}
                                        name="price"
                                        step={1}
                                        defaultValue={1}
                                        data-prefix="$"
                                        onChange={(e) => {
                                            handleChange(e)
                                            console.log(e.target)
                                        }}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn float-btn color-bg"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        let query = ""
                                        for (const key in formData) {
                                            if (formData[key] !== null && formData[key] !== "") {
                                                if (query === "") {
                                                    query = query + `${key}=${formData[key]}`
                                                } else {

                                                    query = query + `&${key}=${formData[key]}`
                                                }
                                            }
                                        }
                                        window.location.href = `/listing?${query}`
                                    }}
                                >
                                    <i className="fa-solid fa-search" /> Search
                                </button>
                            </form>
                        </div>
                    </div>
                    {/* header-search-wrapper end  */}
                    {/* wishlist-wrap*/}
                    <div className="header-modal novis_wishlist tabs-act">
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li className="current" >
                                <a href="#tab-wish" >
                                    {" "}
                                    Wishlist <span>- {wishlist.length}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#tab-compare">
                                    {" "}
                                    Compare <span>- {compare.length}</span>
                                </a>
                            </li>
                        </ul>
                        {/*tabs */}
                        <div className="tabs-container">
                            <div className="tab">
                                {/*tab */}
                                <div id="tab-wish" className="tab-content first-tab">
                                    {/* header-modal-container*/}
                                    {/*widget-posts*/}
                                    <div className="widget-posts  fl-wrap">
                                        <ul className="no-list-style">
                                            {wishlist.map((data) => (
                                                <li key={data.slug}>
                                                    <div className="widget-posts-img">
                                                        <a href={data.slug}>
                                                            <img src={`${apiUrl}/uploads/${data.photo1 ? data.photo1 : data.photo2}`} alt="property" style={{ height: '80px' }} />
                                                        </a>
                                                    </div>
                                                    <div className="widget-posts-descr">
                                                        <h4>
                                                            <a href={data.slug}>
                                                                {data.name}
                                                            </a>
                                                        </h4>
                                                        <div className="geodir-category-location fl-wrap">
                                                            <a href="#">
                                                                <i className="fa-solid fa-map-marker-alt" /> {data.address1}
                                                            </a>
                                                        </div>
                                                        <div className="widget-posts-descr-price">
                                                            <span>Price: </span> $ {(data.purpose !== 'wantToRent') ? data.maxPrice : `${data.rentalPrice} / per month`}
                                                        </div>
                                                        <div
                                                            className="clear-wishlist"
                                                            onClick={(e) => {
                                                                handleWishlist(e, data.id, user.id)
                                                            }}
                                                        >
                                                            <i className="fa-solid fa-trash-alt" />
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                            {/* <li>
                                                <div className="widget-posts-img">
                                                    <a href="listing-single.html">
                                                        <img src="images/all/small/2.jpg" alt="property" />
                                                    </a>
                                                </div>
                                                <div className="widget-posts-descr">
                                                    <h4>
                                                        <a href="listing-single.html">Family House</a>
                                                    </h4>
                                                    <div className="geodir-category-location fl-wrap">
                                                        <a href="#">
                                                            <i className="fas fa-map-marker-alt" /> 34-42
                                                            Montgomery St , NY, USA
                                                        </a>
                                                    </div>
                                                    <div className="widget-posts-descr-price">
                                                        <span>Price: </span> $ 50.000
                                                    </div>
                                                    <div className="clear-wishlist">
                                                        <i className="fa-solid fa-trash-alt" />
                                                    </div>
                                                </div>
                                            </li> */}
                                            {/* <li>
                                                <div className="widget-posts-img">
                                                    <a href="listing-single.html">
                                                        <img src="images/all/small/3.jpg" alt="property" />
                                                    </a>
                                                </div>
                                                <div className="widget-posts-descr">
                                                    <h4>
                                                        <a href="listing-single.html">Apartment to Rent</a>
                                                    </h4>
                                                    <div className="geodir-category-location fl-wrap">
                                                        <a href="#">
                                                            <i className="fas fa-map-marker-alt" />
                                                            75 Prince St, NY, USA
                                                        </a>
                                                    </div>
                                                    <div className="widget-posts-descr-price">
                                                        <span>Price: </span> $100 / per night
                                                    </div>
                                                    <div className="clear-wishlist">
                                                        <i className="fa-solid fa-trash-alt" />
                                                    </div>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                    {/* widget-posts end*/}
                                    {/* header-modal-container end*/}
                                    <div className="header-modal-top fl-wrap">
                                        <div
                                            className="clear_wishlist color-bg"
                                            onClick={(e) => {
                                                window.location.href = '/saved-property'
                                            }}
                                        >
                                            View all
                                        </div>
                                    </div>
                                </div>
                                {/*tab end */}
                                {/*tab */}
                                <div className="tab">
                                    <div id="tab-compare" className="tab-content">
                                        {/* header-modal-container*/}
                                        {/*widget-posts*/}
                                        <div className="widget-posts  fl-wrap">
                                            <ul className="no-list-style">

                                                {compare.map((data) => (
                                                    <li key={data.slug}>
                                                        <div className="widget-posts-img">
                                                            <a href="compare">
                                                                <img src={`${apiUrl}/uploads/${data.photo1 ? data.photo1 : data.photo2}`} alt="property" style={{ height: '89px', objectFit: 'cover' }} />
                                                            </a>
                                                        </div>
                                                        <div className="widget-posts-descr">
                                                            <h4>
                                                                <a href="compare">
                                                                    {data.name}
                                                                </a>
                                                            </h4>
                                                            <div className="geodir-category-location fl-wrap">
                                                                <a href="#">
                                                                    <i className="fa-solid fa-map-marker-alt" /> {data.address1}
                                                                </a>
                                                            </div>
                                                            <div className="widget-posts-descr-price">
                                                                <span>Price: </span> $ {(data.purpose !== 'wantToRent') ? data.maxPrice : `${data.rentalPrice} / per month`}
                                                            </div>
                                                            <div
                                                                className="clear-wishlist"
                                                                onClick={(e) => {
                                                                    handleCompare(e, data.id, user.id)
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-trash-alt" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                                {/* <li>
                                                    <div className="widget-posts-img">
                                                        <a href="listing-single.html">
                                                            <img src="images/all/small/4.jpg" alt="property" />
                                                        </a>
                                                    </div>
                                                    <div className="widget-posts-descr">
                                                        <h4>
                                                            <a href="listing-single.html">
                                                                Gorgeous house for sale
                                                            </a>
                                                        </h4>
                                                        <div className="geodir-category-location fl-wrap">
                                                            <a href="#">
                                                                <i className="fas fa-map-marker-alt" /> 70 Bright
                                                                St New York, USA{" "}
                                                            </a>
                                                        </div>
                                                        <div className="widget-posts-descr-price">
                                                            <span>Price: </span> $ 52.100
                                                        </div>
                                                        <div className="clear-wishlist">
                                                            <i className="fa-solid fa-trash-alt" />
                                                        </div>
                                                    </div>
                                                </li> */}
                                                {/* <li>
                                                    <div className="widget-posts-img">
                                                        <a href="listing-single.html">
                                                            <img src="images/all/small/5.jpg" alt="property" />
                                                        </a>
                                                    </div>
                                                    <div className="widget-posts-descr">
                                                        <h4>
                                                            <a href="listing-single.html">Family Apartments</a>
                                                        </h4>
                                                        <div className="geodir-category-location fl-wrap">
                                                            <a href="#">
                                                                <i className="fas fa-map-marker-alt" /> W 85th St,
                                                                New York, USA{" "}
                                                            </a>
                                                        </div>
                                                        <div className="widget-posts-descr-price">
                                                            <span>Price: </span> $ 72.400
                                                        </div>
                                                        <div className="clear-wishlist">
                                                            <i className="fa-solid fa-trash-alt" />
                                                        </div>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>
                                        {/* widget-posts end*/}
                                        {/* header-modal-container end*/}
                                        <div className="header-modal-top fl-wrap">
                                            <a className="clear_wishlist color-bg" href="/compare">
                                                <i className="fa-solid fa-random" /> Compare
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/*tab end */}
                            </div>
                            {/*tabs end */}
                        </div>
                    </div>
                    {/*wishlist-wrap end */}
                </header>
                {/* header end  */}
                {/* wrapper  */}
                <div id="wrapper">
                </div>
                {/* wrapper end */}
                {/*register form */}
                <div className="main-register-wrap modal">
                    <div className="reg-overlay" />
                    <div className="main-register-holder tabs-act">
                        <div className="main-register-wrapper modal_main fl-wrap">
                            <div className="main-register-header color-bg">
                                <div className="main-register-logo fl-wrap">
                                    <img src="images/white-logo.png" alt="property" />
                                    <h4 style={{ color: "#fff", marginTop: 10 }}>
                                        Login Buyer &amp; Seller
                                    </h4>
                                </div>
                                <div className="main-register-bg">
                                    <div className="mrb_pin" />
                                    <div className="mrb_pin mrb_pin2" />
                                </div>
                                <div className="mrb_dec" />
                                <div className="mrb_dec mrb_dec2" />
                            </div>
                            <div className="main-register">
                                <div className="close-reg">
                                    <i className="fa-solid fa-times" />
                                </div>
                                <ul className="tabs-menu fl-wrap no-list-style">
                                    <li className="current">
                                        <a href="#tab-1">
                                            <i className="fa-solid fa-sign-in-alt" /> Login
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#tab-2">
                                            <i className="fa-solid fa-user-plus" /> Register
                                        </a>
                                    </li>
                                </ul>
                                {/*tabs */}
                                <div className="tabs-container">
                                    <div className="tab">
                                        {/*tab */}
                                        <div id="tab-1" className="tab-content first-tab">
                                            <div className="custom-form">
                                                <form method="post" name="registerform">
                                                    <label>
                                                        Username or Email Address *{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid fa-user" />
                                                        </span>
                                                    </label>
                                                    <input
                                                        name="email"
                                                        type='email'
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value)
                                                            setError('')
                                                        }}
                                                    />
                                                    {error && <p className='text-danger'>{error}</p>}
                                                    <div className="pass-input-wrap fl-wrap">
                                                        <label>
                                                            Password *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-key" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="password"
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder="Enter your password"
                                                            value={password}
                                                            onChange={(e) => {
                                                                setPassword(e.target.value)
                                                                setError("")
                                                            }}
                                                        />
                                                        <span className="eye">
                                                            <i
                                                                className="fa-solid fa-eye"
                                                                onClick={toggleShowPassword}
                                                            />{" "}
                                                        </span>
                                                    </div>

                                                    <ul className="tabs-menu fl-wrap no-list-style lost_password" style={{ width: "60%", fontSize: '10px', padding: '0px 50px', margin: '2px' }}>
                                                        <a href="#tab-3">
                                                            Forgot Password?
                                                        </a>
                                                    </ul>


                                                    {/* <div className="lost_password ">
                                                        <a href="#">Lost Your Password?</a>
                                                    </div> */}
                                                    <div className="filter-tags">
                                                        <input id="check-a3" type="checkbox" name="check" />
                                                        <label htmlFor="check-a3">Remember me</label>
                                                    </div>
                                                    <div className="clearfix" />
                                                    <button
                                                        className="log_btn color-bg"
                                                        onClick={(e) => {
                                                            handleLogin(e)
                                                        }}
                                                    >
                                                        {" "}
                                                        LogIn{" "}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        {/*tab end */}
                                        {/*tab */}
                                        <div className="tab">
                                            <div id="tab-2" className="tab-content">
                                                <div className="custom-form">
                                                    <form
                                                        method="post"
                                                        name="registerform"
                                                        className="main-register-form"
                                                        id="main-register-form2"
                                                        onSubmit={(e) => {
                                                            handleRegister(e)
                                                        }}
                                                    >
                                                        <div className="row row-sm">
                                                            <div className="form-check form-check-inline col">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="userType"
                                                                    required={true}
                                                                    value={0}
                                                                    onChange={(e) => {
                                                                        setUserType(e.target.value)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="user"
                                                                >
                                                                    User
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline col">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="userType"
                                                                    required={true}
                                                                    value={1}
                                                                    onChange={(e) => {
                                                                        setUserType(e.target.value)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="agent"
                                                                >
                                                                    Agent
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline col">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="userType"
                                                                    value={2}
                                                                    required={true}
                                                                    onChange={(e) => {
                                                                        setUserType(e.target.value)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Agency"
                                                                >
                                                                    Agency
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <label>
                                                            Full Name *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-user" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="name"
                                                            type='text'
                                                            placeholder='enter your name'
                                                            required={true}
                                                            onChange={(e) => {
                                                                setName(e.target.value)
                                                            }}
                                                        />
                                                        <label>
                                                            Email Address *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-envelope" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="email"
                                                            type='email'
                                                            placeholder='enter your email'
                                                            required={true}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value)
                                                            }}
                                                        />

                                                        {isEmailVerified &&
                                                            <>
                                                                <div className="pass-input-wrap fl-wrap">
                                                                    <label>
                                                                        Enter OTP *{" "}
                                                                        <span className="dec-icon">
                                                                            <i className="fa-solid fa-code-commit" />
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        name="otp"
                                                                        type="text"
                                                                        placeholder="Enter OTP"
                                                                        value={otp}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            setOtp(e.target.value)
                                                                            setError("")
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="pass-input-wrap fl-wrap">
                                                                    <label>
                                                                        Password *{" "}
                                                                        <span className="dec-icon">
                                                                            <i className="fa-solid fa-key" />
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        name="password"
                                                                        type={showPassword ? "text" : "password"}
                                                                        placeholder='enter your password'
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            setPassword(e.target.value)
                                                                        }}
                                                                    />
                                                                    <span className="eye">
                                                                        <i
                                                                            className="fa-solid fa-eye"
                                                                            onClick={toggleShowPassword}
                                                                        />
                                                                        {" "}
                                                                    </span>
                                                                </div>
                                                                <div className="filter-tags ft-list">
                                                                    <input
                                                                        id="check-a2"
                                                                        type="checkbox"
                                                                        name="check"
                                                                        required={true}
                                                                    />
                                                                    <label htmlFor="check-a2">
                                                                        I agree to the <a href="#">Privacy Policy</a> and{" "}
                                                                        <a href="#">Terms and Conditions</a>
                                                                    </label>
                                                                </div>
                                                            </>

                                                        }

                                                        {isEmailVerified ?
                                                            <>
                                                                <div className="clearfix" />
                                                                <button
                                                                    type="submit"
                                                                    className="log_btn color-bg"
                                                                // onClick={(e) => {
                                                                //     handleRegister(e)
                                                                // }}
                                                                >
                                                                    {" "}
                                                                    Register{" "}
                                                                </button>
                                                                <ul className="tabs-menu fl-wrap no-list-style lost_password" style={{ width: "auto", fontSize: '10px', padding: '0px 50px' }}>
                                                                    <a
                                                                        onClick={(e) => {
                                                                            handleEmailVerification(e)
                                                                        }}
                                                                        style={{
                                                                            float: "left",
                                                                            color: "#7d93b2",
                                                                            fontSize: "12px",
                                                                            fontWeight: "600",
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        Send Again
                                                                    </a>
                                                                </ul>
                                                            </>
                                                            :
                                                            <button
                                                                type="button"
                                                                className="log_btn color-bg"
                                                                onClick={(e) => {
                                                                    handleEmailVerificationForRegister(e)

                                                                }}
                                                            >
                                                                <ul className="tabs-menu fl-wrap no-list-style " style={{ width: "60%", padding: '0px 20px', margin: '2px', color: 'white' }}>
                                                                    {/* <a href="#tab-4"> */}
                                                                    Verify
                                                                    {/* </a> */}
                                                                </ul>

                                                                {" "}
                                                            </button>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/*tab end */}
                                        <div className="tab">
                                            <div id="tab-3" className="tab-content">
                                                <div className="custom-form">
                                                    <form
                                                        method="post"
                                                        name="registerform"
                                                        className="main-register-form"
                                                        id="main-register-form2"
                                                    // onSubmit={(e) => {
                                                    //     handleEmailVerification(e)
                                                    // }}
                                                    >
                                                        <div className="row row-sm">

                                                        </div>

                                                        <label>
                                                            Enter Registered Email Address *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-envelope" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="email"
                                                            type='email'
                                                            placeholder='Enter your registered email'
                                                            required={true}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value)
                                                            }}
                                                        />

                                                        {isEmailVerified &&
                                                            <>
                                                                <div className="pass-input-wrap fl-wrap">
                                                                    <label>
                                                                        Enter OTP *{" "}
                                                                        <span className="dec-icon">
                                                                            <i className="fa-solid fa-code-commit" />
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        name="otp"
                                                                        type="text"
                                                                        placeholder="Enter OTP"
                                                                        value={otp}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            setOtp(e.target.value)
                                                                            setError("")
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="pass-input-wrap fl-wrap">
                                                                    <label>
                                                                        Enter New Password *{" "}
                                                                        <span className="dec-icon">
                                                                            <i className="fa-solid fa-key" />
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        name="password"
                                                                        type={showPassword ? "text" : "password"}
                                                                        placeholder="Enter your password"
                                                                        value={password}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            setPassword(e.target.value)
                                                                            setError("")
                                                                        }}
                                                                    />
                                                                    <span className="eye">
                                                                        <i
                                                                            className="fa-solid fa-eye"
                                                                            onClick={toggleShowPassword}
                                                                        />{" "}
                                                                    </span>
                                                                </div>
                                                            </>

                                                        }
                                                        <div className="clearfix" />
                                                        {isEmailVerified ?
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <button
                                                                    type="submit"
                                                                    className="log_btn color-bg"
                                                                    onClick={(e) => {
                                                                        handleForgotPassword(e)
                                                                    }}
                                                                >
                                                                    <ul className="tabs-menu fl-wrap no-list-style " style={{ width: "60%", padding: '0px 20px', margin: '2px', color: 'white' }}>
                                                                        {/* <a href="#tab-4"> */}
                                                                        Submit
                                                                        {/* </a> */}
                                                                    </ul>
                                                                    {" "}
                                                                </button>
                                                                <ul className="tabs-menu fl-wrap no-list-style lost_password" style={{ width: "auto", fontSize: '10px', padding: '0px 50px' }}>
                                                                    <a
                                                                        onClick={(e) => {
                                                                            handleEmailVerification(e)
                                                                        }}
                                                                        style={{
                                                                            float: "left",
                                                                            color: "#7d93b2",
                                                                            fontSize: "12px",
                                                                            fontWeight: "600",
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        Send Again
                                                                    </a>
                                                                </ul>
                                                            </div>
                                                            :
                                                            <button
                                                                type="button"
                                                                className="log_btn color-bg"
                                                                onClick={(e) => {
                                                                    handleEmailVerification(e)

                                                                }}
                                                            >
                                                                <ul className="tabs-menu fl-wrap no-list-style " style={{ width: "60%", padding: '0px 20px', margin: '2px', color: 'white' }}>
                                                                    {/* <a href="#tab-4"> */}
                                                                    Verify
                                                                    {/* </a> */}
                                                                </ul>

                                                                {" "}
                                                            </button>
                                                        }

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="tab">
                                            <div id="tab-4" className="tab-content">
                                                <div className="custom-form">
                                                    <form
                                                        method="post"
                                                        name="registerform"
                                                        className="main-register-form"
                                                        id="main-register-form2"
                                                        onSubmit={(e) => {
                                                            handleForgotPassword(e)
                                                        }}
                                                    >
                                                        <div className="row row-sm">

                                                        </div>

                                                        <label>
                                                            Enter New Password *{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-envelope" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            name="password"
                                                            type='password'
                                                            placeholder='enter your email'
                                                            required={true}
                                                            onChange={(e) => {
                                                                setPassword(e.target.value)
                                                            }}
                                                        />

                                                        <div className="clearfix" />
                                                        <button
                                                            type="submit"
                                                            className="log_btn color-bg"
                                                        // onClick={(e) => {
                                                        //     handleRegister(e)
                                                        // }}
                                                        >

                                                            Submit
                                                            {" "}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/*tabs end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*register form end */}
                {/*secondary-nav */}
                <div className="secondary-nav">
                    <ul>
                        <li>
                            <a
                                className="tolt"
                                data-microtip-position="left"
                                data-tooltip="Sell Property"
                                onClick={() => {
                                    if (!(Object.keys(user).length)) {
                                        document.getElementById('open-login').click();
                                    } else {
                                        window.location.href = '/add';
                                    }
                                }}
                            >
                                <i className="fa-regular fa-building" />
                            </a>
                        </li>
                        <li>
                            <a
                                className="tolt"
                                data-microtip-position="left"
                                data-tooltip="Buy Property"
                                onClick={() => {
                                    window.location.href = '/listing?=';
                                }}
                            >
                                <i className="fa-solid fa-bag-shopping" />

                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                // className="tolt"
                                data-microtip-position="left"
                            // data-tooltip="Your Compare"
                            >
                                <i className="fa-solid fa-angles-up" />
                            </a>
                        </li>
                    </ul>
                    <div className="progress-indicator">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                            <circle
                                cx={16}
                                cy={16}
                                r="15.9155"
                                className="progress-bar__background"
                            />
                            <circle
                                cx={16}
                                cy={16}
                                r="15.9155"
                                className="progress-bar__progress 
                            js-progress-bar"
                            />
                        </svg>
                    </div>
                </div>
                {/*secondary-nav end */}
                <a className="to-top color-bg">
                    <i className="fa-solid fa-caret-up" />
                </a>
                {/*map-modal */}
                <div className="map-modal-wrap">
                    <div className="map-modal-wrap-overlay" />
                    <div className="map-modal-item">
                        <div className="map-modal-container fl-wrap">
                            <h3>
                                {" "}
                                <span>Listing Title </span>
                            </h3>
                            <div className="map-modal-close">
                                <i className="far fa-times" />
                            </div>
                            <div className="map-modal fl-wrap">
                                <div id="singleMap" data-latitude="40.7" data-longitude="-73.1" />
                                <div className="scrollContorl" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*map-modal end */}
            </div >
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div >


    )
}



export default Header2
