import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'
import Slider from "react-slick";



function HeroSection({ }) {


    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    // const Token = localStorage.getItem("Token");

    // const [cityOptions, setCityOptions] = useState([])
    // const [stateOptions, setStateOptions] = useState([])
    // const [stateId, setStateId] = useState('')
    const [typeOptions, setTypeOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [typeId, setTypeId] = useState(1)
    const [filteredCities, setFilteredCities] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [suggestion, setSuggestion] = useState([])
    const [banners, setBanners] = useState([])


    const initialFormData = {
        purpose: "",
        search: "",
        category: "",
        price: "",
    };
    const [formData, setFormData] = useState(initialFormData);

    const insertBrAfterWords = (text, wordCount) => {
        const words = text.split(' ');
        const result = [];

        for (let i = 0; i < words.length; i += wordCount) {
            result.push(words.slice(i, i + wordCount).join(' '));
            if (i + wordCount < words.length) {
                result.push(<br key={i} />);
            }
        }

        return result;
    };

    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (name === 'type') {
            const selectedDataKey = e.target.options[e.target.selectedIndex].getAttribute('dataKey')
            setTypeId(selectedDataKey)
        }

        if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    useEffect(() => {
        if (searchInput === '') {
            setFilteredCities([]);
        } else {
            const filtered = suggestion.filter(city =>
                city.toLowerCase().startsWith(searchInput.toLowerCase())
            );
            setFilteredCities(filtered);
        }
    }, [searchInput]);

    useEffect(() => {
        fetch(`${apiUrl}/category/getAllCategory`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('dataaaaaaaaaaa', data.data)
                    setTypeOptions(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/category/getAllCategoryData/${typeId}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('dataaaaaaaaaaa', data.data)
                    setCategoryOptions(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [typeId, apiKey, apiUrl])

    useEffect(() => {
        fetch(`${apiUrl}/banner/getAllBanner`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {

                    const filteredData = data.data.filter((banner) => banner.position === 'MainSliderBanner' && banner.isVisible)

                    console.log('filteredData =>', filteredData)

                    setBanners(filteredData);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl])

    // useEffect(() => {
    //     fetch(`${apiUrl}/employee/allStates/207`, {
    //         headers: {
    //             'Authorization': `Bearer ${apiKey}`,
    //         }
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data && Array.isArray(data.data)) {
    //                 console.log('dataaaaaaaaaaa', data.data)
    //                 setStateOptions(data.data);
    //             } else {
    //                 console.error('API response does not contain an array:', data);
    //             }
    //         })
    //         .catch(error => {
    //             console.log('Error fetching masters data:', error);
    //         });
    // }, [apiKey])


    useEffect(() => {

        async function getSuggestion() {

            const resData = await fetch(`${apiUrl}/property/suggestions`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${apiKey}`
                    }
                }
            )
            const response = await resData.json();

            if (response.status !== "error") {
                setSuggestion(response.data)
            }

        }

        getSuggestion()


    }, [apiUrl, apiKey])


    // useEffect(() => {

    //     if (stateId) {

    //         fetch(`${apiUrl}/employee/allcities/${stateId}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${apiKey}`,
    //             }
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data && Array.isArray(data.data)) {
    //                     console.log('dataaaaaaaaaaa', data.data)
    //                     setCityOptions(data.data);
    //                 } else {
    //                     console.error('API response does not contain an array:', data);
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log('Error fetching masters data:', error);
    //             });
    //     }
    // }, [stateId])

    const settings = {
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        waitForAnimate: false,
        arrows: false

    };


    return (<section className="hero-section gray-bg">
        <div className="bg-wrap-hero-section" >
            <Slider {...settings}>
                {banners.map((banner) => <div key={banner.id}>
                    < img src={banner.imageForWebUrl} alt="banner" />
                </div>
                )}
            </Slider>
        </div>
        <div className="container">
            <Slider {...settings}>
                {banners.map((banner) => <div className="hero-title hero-title_small" key={banner.id}>
                    <h4 style={{boxShadow:'-moz-initial'}}>{banner.imageTitle}</h4>
                    <h2>
                        {insertBrAfterWords(banner.imageDescription, 6)}
                        {/* {banner.imageDescription} */}
                    </h2>
                </div>
                )}
            </Slider>
            {/* <div className="hero-title hero-title_small">

                <h4>Real Estate Searching Platform</h4>
                <h2>
                    Find The House of Your Dream <br />
                    Using Our Platform
                </h2>
            </div> */}
            <div className="main-search-input-wrap shadow_msiw">
                <div className="main-search-input fl-wrap">
                    <div className="main-search-input-item">
                        <select
                            data-placeholder="All Categories"
                            className="form-select"
                            name='purpose'
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            style={{
                                height: '58px'
                            }}>
                            <option value={""}>Looking for</option>
                            <option value={"wantToRent"}>For Rent</option>
                            <option value={"wantToSell"}>For Sale</option>
                            <option value={"bothSellRent"}>For Both</option>
                        </select>
                    </div>
                    <div className="main-search-input-item">
                        <select
                            data-placeholder="Categories"
                            className="form-select"
                            name='type'
                            onChange={(e) => {
                                setTypeId(e.target.value)
                                handleChange(e)
                            }}
                            style={{
                                height: '58px'
                            }}>
                            <option value={""}>All Categories</option>
                            {typeOptions.map((department) => (
                                <option key={department.id} value={department.name} dataKey={department.id}>
                                    {department.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="main-search-input-item">
                        <select
                            data-placeholder="Sub Category"
                            className="form-select"
                            style={{
                                height: '58px'
                            }}
                            name='category'
                            onChange={e => {
                                handleChange(e)
                            }}>
                            <option>All Sub Category</option>
                            {categoryOptions.map(department => <option key={department.id} value={department.name}>
                                {department.name}
                            </option>)}
                        </select>
                    </div>

                    {/* <div
                        className='header-searchbar'
                    >
                        <input
                            className='header-searchbar-input'
                            type="text"
                            name='search'
                            placeholder="Search..."
                            value={searchInput}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    let query = ""
                                    for (const key in formData) {
                                        if (formData[key] !== null && formData[key] !== "") {
                                            if (query === "") {
                                                query = query + `${key}=${formData[key]}`
                                            } else {

                                                query = query + `&${key}=${formData[key]}`
                                            }
                                        }
                                    }
                                    window.location.href = `/listing?${query}`
                                }
                            }}
                            onChange={(e) => {
                                console.log(e.target.value)

                                handleChange(e)
                                setSearchInput(e.target.value)
                            }}

                        // style={{ width: '200px', padding: '10px' }}
                        />
                        {filteredCities.length > 0 && (
                            <ul style={{
                                listStyle: 'none',
                                padding: 0,
                                margin: 0,
                                marginTop: '10px',
                                display: 'block',
                                position: 'absolute',
                                background: 'white',
                                width: '100%',
                                top: '40px',
                                borderRadius: '5px',
                                textAlign: 'left',
                                paddingLeft: '10px',
                            }}>
                                {filteredCities.map((suggestion, index) => (
                                    <li key={index}
                                        className='searchbar-suggestion'
                                        onClick={(e) => {
                                            e.preventDefault()

                                            window.location.href = `/listing?search=${suggestion}`
                                            setSearchInput(suggestion)
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}

                        <div style={{
                            height: '40px',
                            width: '50px'
                        }}
                            onClick={(e) => {
                                e.preventDefault()
                                let query = ""
                                for (const key in formData) {
                                    if (formData[key] !== null && formData[key] !== "") {
                                        if (query === "") {
                                            query = query + `${key}=${formData[key]}`
                                        } else {

                                            query = query + `&${key}=${formData[key]}`
                                        }
                                    }
                                }
                                window.location.href = `/listing?${query}`
                            }}
                        >
                            <i className="fa-solid fa-search" />

                        </div>
                    </div> */}



                    <div className="main-search-input-item">
                        <input
                            className='header-searchbar-input'
                            type="text"
                            name='search'
                            placeholder="Search..."
                            value={searchInput}
                            style={{
                                backgroundColor: 'white',
                                padding: '0px'
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    let query = ""
                                    for (const key in formData) {
                                        if (formData[key] !== null && formData[key] !== "") {
                                            if (query === "") {
                                                query = query + `${key}=${formData[key]}`
                                            } else {

                                                query = query + `&${key}=${formData[key]}`
                                            }
                                        }
                                    }
                                    window.location.href = `/listing?${query}`
                                }
                            }}
                            onChange={(e) => {
                                console.log(e.target.value)

                                handleChange(e)
                                setSearchInput(e.target.value)
                            }}

                        // style={{ width: '200px', padding: '10px' }}
                        />
                        {filteredCities.length > 0 && (
                            <ul style={{
                                listStyle: 'none',
                                padding: 0,
                                margin: 0,
                                marginTop: '10px',
                                display: 'block',
                                position: 'absolute',
                                background: 'white',
                                width: '100%',
                                top: '40px',
                                borderRadius: '5px',
                                textAlign: 'left',
                                paddingLeft: '10px',
                            }}>
                                {filteredCities.map((suggestion, index) => (
                                    <li key={index}
                                        className='searchbar-suggestion'
                                        onClick={(e) => {
                                            e.preventDefault()

                                            window.location.href = `/listing?search=${suggestion}`
                                            setSearchInput(suggestion)
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* <div className="main-search-input-item">
                        <select
                            data-placeholder="All Categories"
                            className="form-select"
                            name='city'
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            style={{
                                height: '58px'
                            }}>
                            <option>All Cities</option>
                            {cityOptions.map(department => <option key={department.id} value={department.id}>
                                {department.name}
                            </option>)}
                        </select>
                    </div> */}
                    <button
                        className="main-search-button color-bg" style={{ backgroundColor: ' #E48211' }}
                        onClick={(e) => {
                            e.preventDefault()
                            let query = ""
                            for (const key in formData) {
                                if (formData[key] !== null && formData[key] !== "") {
                                    if (query === "") {
                                        query = query + `${key}=${formData[key]}`
                                    } else {
                                        query = query + `&${key}=${formData[key]}`
                                    }
                                }
                            }
                            window.location.href = `/listing?${query}`
                        }}>
                        {" "}
                        Search <FontAwesomeIcon icon="fa-solid  fa-search" />{" "}
                    </button>
                </div>
            </div>
            <div className="hero-notifer fl-wrap">
                Need more search options?{" "}
                <button
                    className="btn-sm text-white rounded p-1"
                    style={{ background: 'black' }}
                    onClick={(e) => {
                        window.location.href = 'listing'
                    }}
                >Advanced Search</button>{" "}
            </div>
        </div>
    </section>);
}

export default HeroSection
