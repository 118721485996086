// import React, { useEffect, useRef, useState } from 'react';
// import { GoogleMap } from '@react-google-maps/api';

// const API_KEY = process.env.REACT_APP_MAP_API_KEY;
// const MAP_ID = process.env.REACT_APP_MAP_API_KEY; // Add your Map ID here

// function MapMarker({ propertyList }) {
//   const [mapLoaded, setMapLoaded] = useState(false);
//   const [defaultCenter, setDefaultCenter] = useState({ lat: 39.577953515499246, lng: -3.0096964781062954 }); 
//   const mapRef = useRef(null);

//   console.log('propertyList =>', propertyList);

//   useEffect(() => {
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setDefaultCenter({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//         }
//       );
//     } else {
//       console.error('Geolocation is not available in this browser.');
//     }
//   }, []);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,marker`;
//     script.async = true;
//     script.onload = () => setMapLoaded(true);
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   useEffect(() => {
//     if (mapLoaded && mapRef.current) {
//       const { google } = window;
//       const map = new google.maps.Map(mapRef.current, {
//         center: defaultCenter,
//         zoom: 10,
//         mapId: MAP_ID,
//       });

//       if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
//         propertyList.forEach((prop) => {
//           const marker = new google.maps.marker.AdvancedMarkerElement({
//             position: { lat: +prop.latitude, lng: +prop.longitude },
//             map,
//           });

//           marker.addListener('click', () => {
//             window.location.href = prop.slug;
//           });
//         });
//       } else {
//         console.error('AdvancedMarkerElement is not available.');
//       }
//     }
//   }, [mapLoaded, defaultCenter, propertyList]);

//   const mapStyles = {
//     height: "75vh",
//     width: "100%"
//   };

//   return (
//     <div>
//       {mapLoaded ? (
//         <div ref={mapRef} style={mapStyles}></div>
//       ) : (
//         <div>Loading Maps...</div>
//       )}
//     </div>
//   );
// }

// export default MapMarker;





import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const API_KEY = process.env.REACT_APP_MAP_API_KEY;

function MapMarker({ propertyList }) {
  const [mapLoaded, setMapLoaded] = useState(false);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 40.41345088305735,
    lng: -3.708791763896553
  })

  console.log('propertyList =>', propertyList)

  useEffect(() => {
    // Check if geolocation is available in the browser
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude, 'jjjjjjjjj')
          setDefaultCenter({
            lat: latitude,
            lng: longitude
          })
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => setMapLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const mapStyles = {
    height: "75vh",
    width: "100%"
  };

  return (
    <div>
      {mapLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={10}
          center={defaultCenter}
        >
          {/* {mapLoaded && <MarkerF position={defaultCenter} />}
          {mapLoaded && <MarkerF position={markerPosition} />} */}
          {propertyList.map((prop) => (
            <MarkerF
              key={prop.slug}
              position={{
                lat: +prop.latitude,
                lng: +prop.longitude
              }}
              data-toggle="tooltip"
              data-placement="top"
              title={`${prop.name}`}
              onClick={() => {
                window.location.href = prop.slug
              }}
            />
          ))}
        </GoogleMap>
      )}
      {!mapLoaded && <div>Loading Maps...</div>}
    </div>
  );
}

export default MapMarker;
